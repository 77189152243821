import React, { useState, useEffect } from 'react';

const AlternatingWords = ({ words, interval, classNames }) => {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, interval);

    return () => clearInterval(intervalId);
  }, [words, interval]);

  return (
    <p>
      {words.map((word, index) => (
        <span key={index} className={classNames[index]}>
          {index === currentWordIndex ? word : ''}
        </span>
      ))}
    </p>
  );
};

export default AlternatingWords;
