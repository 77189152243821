import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import './assets/sass/custom.scss';
import './App.css';
import NavBar from './components/NavBar';
import HomePage from './pages/HomePage';
import PreciosPage from './pages/Precios';
import ContactSection from './components/Contact';
import CurriculumPage from './pages/Curriculum';
import NotFoundSection from './components/404Section';
import AvisoPrivPage from './pages/AvisoPriv';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollTop';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Redirector from './components/Redirector';
import { locations } from './components/extras/Direcciones';
import BotonWsp from './components/extras/BotonWsp';
import Sitemap from './components/Sitemap'; // Importa el componente Sitemap

const whatsappNumber = locations.location1.whatsapp;
const phoneNumber = locations.location1.phone;

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <HelmetProvider>
        <div className="App">
          <NavBar />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/precios" element={<PreciosPage />} />
            <Route path="/precios-manga-gastrica-endoscopica" element={<PreciosPage />} />
            <Route path="/ubicaciones" element={<ContactSection />} />
            <Route path="/Doctor-Juan-Antonio" element={<CurriculumPage />} />
            <Route path="/Aviso-Privacidad" element={<AvisoPrivPage />} />
            <Route path="/404" element={<NotFoundSection />} />
            <Route path="/sitemap.xml" element={<Sitemap />} /> {/* Define la ruta para sitemap */}
            <Route path="*" element={<Redirector />} />
          </Routes>
          <Footer />
          <BotonWsp whatsappNumber={whatsappNumber} phoneNumber={phoneNumber} />
        </div>
      </HelmetProvider>
    </Router>
  );
}

export default App;
