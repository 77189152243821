import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
// import AlternatingWords from './extras/words';
import '../assets/css/InformacionSection/styles.css';
import VideoButton from '../components/button/VideoButton';


function infoSection() {

  return (
    <section className="info section-info ripple-background">
      <Container data-aos="fade-up">
        <div>
        <h2 data-aos="fade-up" className='text-secondary'>¿Cómo funciona la <span className='text-primary'>MANGA GÁSTRICA Endoscópica</span>?</h2>
        </div>
        <Row className="align-items-center flex-column">
          <Col className="d-flex" data-aos="fade-up" data-aos-delay="300" >
            <div className="content ps-0 ps-lg-5">
              <p className="text-lg-left">La gastroplastia endoscópica en manga es un procedimiento mínimamente invasivo para perder peso. A diferencia de otros métodos, <span className='text-primary'>no se realizan cortes</span>. En cambio, se introduce un dispositivo de sutura a través de la garganta hasta el estómago.</p>
              <p className="text-lg-left">Allí, el <span className='text-primary'>Dr. Juan Antonio</span> coloca suturas para reducir el tamaño del estómago. Al limitar la cantidad de alimentos que puedes ingerir, la gastroplastia endoscópica en manga conduce a una pérdida de peso importante. Además, al ser mínimamente invasivo, reduce el riesgo de complicaciones y permite un rápido retorno a las actividades diarias.</p>
              <p className="text-lg-left">Sin embargo, como con cualquier método para perder peso, es crucial comprometerse con un estilo de vida saludable. Esto implica realizar cambios permanentes en la alimentación y mantener una rutina regular de ejercicio para asegurar el éxito a largo plazo de la gastroplastia endoscópica en manga</p>
            </div>
          </Col>
            <Col className="content ps-0">
              <div className="position-relative mt-4">
                <VideoButton videoId="CkycB6jn5ss" title="Colocación de Manga Gástrica Endoscópica" />
              </div>
            </Col>
        </Row>
      </Container>
    </section>
  );
}

export default infoSection;

