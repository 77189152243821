
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Row, Col } from 'react-bootstrap';
import '../assets/css/DoctorSection/styles.css';
import imageDoctor from '../assets/images/Doctores/cirujano-juan-antonio-sanchez-valle.webp'
import ContactSection from '../components/Contact';


const  CurriculumPage = () => (
    <>
      <Helmet>
        <title>Curriculum - Dr. Juan Antonio</title>
        <meta name="description" content="Descubre la manga gástrica endoscópica en nuestra página principal." />
        <meta name="keywords" content="Manga gástrica, Home, Página principal" />
      </Helmet>
      <section id="doctor" className="doctor section-doctor">
      <Container className="spacing-2" data-aos="fade-up">
        <Row className="justify-content-center gy-5">
          <Col lg={6} order={{ xs: 3, lg: 2 }} className="d-flex flex-column justify-content-center align-items-center align-items-lg-start text-center text-lg-start order-1 order-xl-1">
          <h1 data-aos="fade-up" className='text-secondary my-4'>Cirujano Especialista<br></br> <span className='text-primary opacity-75'>Juan Antonio Sánchez Valle</span></h1>
          <p className="h5 py-2" data-aos="fade-up" data-aos-delay="100">Cédula Profesional: 4178012</p>
          <p className="h5 py-2 text-primary" data-aos="fade-up" data-aos-delay="100">Cédula de Especialista: 4512758</p>
          <p className="h5 py-2 text-primary" data-aos="fade-up" data-aos-delay="100">Cédula de Sub - Especialista: 4743246</p>
            <ul className='text-start unlisted' data-aos="fade-up" data-aos-delay="100">
              <li>Realizó Estudios Universitarios de Pregrado en la Carrera de Medicina</li>
              <li>Título Académico de Médico Cirujano.</li>
              <li>Especialista en Cirugía General, Gastrointestinal y Laparoscópica en Hospital de Especialidades, Centro Médico Nacional Siglo XXI, Instituto Mexicano del Seguro Social. Residencia de 4 años.</li>
              <li>Sub-Especialista en Coloproctología en Hospital General de México - Secretaría de Salud. Residencia de 2 años en la especialidad de Coloproctologia, Endoscopia Bariatrica en BAJA HOSPITAL & MEDICAL CENTER. Tijuana Baja California. Instituto Tecnologico de Monterrey.</li>
              <li>Más de 20 años de experiencia en cirugía gastrointestinal y laparoscópica avanzada y endoscopía digestva y bariática lo certifican.</li>
              <li>Especialista en Endoscopia Bariátrica en BAJA HOSPITAL & MEDICAL CENTER. Tijuana Baja California. Instituto Tecnológico de Monterrey.</li>
              <li>Cirujano Certificado Consejo Mexicano de Cirugia General y Colegio Mexicano de Especialistas en Coloproctologia.</li>
            </ul>
          </Col>
          <Col lg={6} className="text-center text-lg-start d-flex align-items-center order-0">
            <img src={imageDoctor} className="img-fluid rounded-4" alt="" data-aos="zoom-out" data-aos-delay="300" />
          </Col>
        </Row>
      </Container>     
      <Container className="spacing-2" data-aos="fade-up">
        <Row className="justify-content-center gy-5">
          <Col className="d-flex flex-column justify-content-center align-items-center align-items-lg-start text-center text-lg-start">
            <h2>Estudios Universitarios en Medicina General</h2>
            <p>UNIVERSIDAD NACIONAL AUTÓNOMA DE MÉXICO - FACULTAD DE MEDICINA</p>
            <h4>Título Académico de Médico Cirujano.</h4>
            <h2>Sub-Especialización en Coloproctología</h2>
            <p>Residencia de 2 años en la especialidad de Coloproctología.</p>

            <h2>Certificaciones</h2>
            <ul>
                <li>Consejo Mexicano de Cirugía General, A.C.</li>
                <li>Consejo Mexicano de Especialistas en Enfermedades del Colon y del Recto.</li>
            </ul>

            <h2>Asociaciones</h2>
            <ul>
                <li>Colegio Mexicano de Especialistas en Coloproctología</li>
                <li>Sociedad Médica Clínica Londres</li>
            </ul>

            <h2>Publicaciones en Revistas y Medios Digitales</h2>
            <ul>
                <li><a target='_blank' rel='noreferrer' href="https://www.medigraphic.com/pdfs/circir/cc-2004/cc045m.pdf">ABSCESO DE PSOAS TUBERCULOSO EN PACIENTES CON VIH.</a></li>
                <li>ABSCESO DEL MUSCULO PSOAS. SITUACION ACTUAL DE LA ENFERMEDAD.</li>
                <li>DETERMINACIÓN DE LA ACCIÓN ANALGÉSICA DEL KETOROLACO EN PACIENTES TRAUMATOLÓGICOS POS OPERADOS.</li>
                <li>TEST DE WAYNE, DIAGNOSTICO CLÍNICO DE TIROTOXICOSIS. ALTERNATIVA PARA MEDIOS SOCIO ECONÓMICOS BAJOS.</li>
                <li>PROTOCOLO DE TRATAMIENTO DE ENFERMEDADES DIARREICA AGUDA EN PEDIATRÍA.</li>
                <li>ENFERMEDAD POR REFLUJO ESOFÁGICO, UN TRASTORNO DE LA MOTILIDAD QUE SE TRATA CON ANTISECRETORES.</li>
                <li>COLECISTECTOMIA DIFÍCIL, ALGUNAS ESTRATEGIAS PARA SU MANEJO.</li>
                <li>DRENAJE LAPAROSCÓPICO DE ABSCESO HEPÁTICO.</li>
                <li>INCOMPETENCIA DEL ESFÍNTER ESOFÁGICO INFERIOR Y DISMOTILIDAD ESOFÁGICA, UNA COMBINACIÓN FRECUENTE.</li>
                <li>COLECISTECTOMIA LAPAROSCÓPICA Y OBESIDAD.</li>
            </ul>

            <h2>Asistencia a Congresos, Jornadas, Seminarios, Simposios y Cursos de Actualización</h2>
            <ul>
                <li>XXXIII CONGRESO INTERNACIONAL DE CIRUGIA GENERAL. Asociación Mexicana De Cirugía General, Acapulco 1 a 7 de Noviembre del 2009.</li>
                <li>18o. CONGRESO INTERNACIONAL DE CIRUGIA ENDOSCOPICA. Asociación Mexicana De Cirugia Endoscopica Puerto Vallarta, Jalisco Septiembre 2009.</li>
                <li>XXXIX CONGRESO NACIONAL DE COLOPROCTOLOGIA. Sociedad Mexicana De Coloproctologia, Colegio Mexicano De Especialistas en Coloproctologia, A.C. Zacatecas 12 al 15 de Agosto 2009.</li>
                <li>V CONGRESO GRUPO ÁNGELES. Grupo Ángeles Servicios de Salud. México DF, Octubre 2008.</li>
                <li>V CONGRESO SALUD SIN FRONTERAS. Grupo Ángeles Servicios de Salud. Universidad Nacional Autónoma de México - Facultad de Medicina. División de Estudios de Posgrado. México DF, 23 al 25 de Octubre del 2008.</li>
                <li>XXXIII CONGRESO NACIONAL DE COLOPROCTOLOGIA. Sociedad Mexicana De Coloproctologia, Colegio Mexicano De Especialistas en Coloproctologia Del Estado De Jalisco, A.C. Puerto Vallarta, Jalisco Mayo 2008.</li>
                <li>CONGRESO INTERNACIONAL DE CIRUGÍA ENDOSCÓPICA. Consejo Mexicano de Cirugía Endoscópica. Veracruz 30 de abril al 4 de Mayo 2007.</li>
                <li>IV CURSO INTERNACIONAL DE ACTUALIZACIÓN EN COLON, RECTO Y ANO. Instituto Nacional de Ciencias Médicas y Nutrición "Salvador Subirán", Escuela de Medicina del Tecnológico de Monterrey, CCM, Sociedad Mexicana de Coloproctología, A.C. Asociación Mexicana de Cirugía General, A.C. Enero 2006.</li>
                <li>IV CURSO INTERNACIONAL DE ACTUALIZACIÓN EN CIRUGÍA GENERAL. Instituto Nacional de Ciencias Médicas y Nutrición "Salvador Subirán", Escuela de Medicina del Monterrey, CCM, Asociación Mexicana de Cirugía General, A.C. Enero 2006.</li>
                <li>III CURSO INTERNACIONAL DE ACTUALIZACIÓN EN COLON, RECTO Y ANO. Instituto Nacional de Ciencias Médicas y Nutrición "Salvador Subirán", Instituto Tecnológico de Monterrey, CCM, Asociación Mexicana de Gastroenterología, A.C., Sociedad Mexicana de Coloproctología, A.C. Asociación Mexicana de Cirugía General, A.C. Enero 2005.</li>
                <li>III CURSO INTERNACIONAL DE ACTUALIZACIÓN EN CIRUGÍA GENERAL. Instituto Nacional de Ciencias Médicas y Nutrición "Salvador Subirán", Instituto Tecnológico de Monterrey, CCM, Asociación Mexicana de Cirugía General, A.C. Enero 2005.</li>
                <li>CURSO DE REDACCIÓN DEL ESCRITO MÉDICO. Dirección de Enseñanza del Hospital General de México.</li>
                <li>1ER CONGRESO INTERNACIONAL DE ACTUALIZACIÓN EN EL MANEJO DE HERIDAS. Hospital Ángeles Metropolitano, 3M, TecSA, MSD, KCI.</li>
                <li>IV CURSO DE TEMAS SELECTOS DE CIRUGÍA. Dirección de Enseñanza del Hospital General de México O.D., Sociedad Medica del Hospital General de México, A.C., Facultad de Medicina de la UNAM. Septiembre 1999.</li>
                <li>IX CURSO DE SISTEMATIZACIÓN DE CONOCIMIENTO MÉDICOS - UNAM. Facultad de Medicina de la Universidad Nacional Autónoma de México. Agosto 1999.</li>
                <li>PRIMER MODULO DEL CURSO MEXICANO PARA MÉDICOS. AMPAC, Latin American Society of Hypertension. ALAD, Asociación Mexicana de Médicos Familiares y Médicos Generales. Agosto de 1999.</li>
                <li>XV CURSO INTERNACIONAL DE INFECTOLOGÍA. Dirección de Enseñanza del Hospital General de México O.D., Sociedad medica del Hospital General de México, A.C., Facultad de Medicina de la UNAM. Julio 1999.</li>
                <li>TALLER DE TERAPÉUTICA ANTIINFECCIOSA Y VACUNACIÓN. Dirección de Enseñanza del Hospital General de México O.D., Sociedad medica del Hospital General de México, A.C., Julio 1999.</li>
            </ul>
          </Col>
        </Row>
      </Container>  

      </section>
      <ContactSection/>
    </>
  );


export default CurriculumPage;

