import React from 'react';
import { Container, Row, Col,} from 'react-bootstrap';
import '../assets/css/hero/styles.css';

function HeroSection() {

  return (
    <section id="hero" className="hero d-flex align-items-center section-bg backgroundHero">
      <Container>
        <Row className="justify-content-between gy-5">
          <Col lg={5} order={2} className="order-lg-1 d-flex flex-column justify-content-center align-items-center align-items-lg-start text-center text-lg-start">
            <h1 data-aos="fade-up"><br />MANGA GÁSTRICA <span className='text-primary'>Endoscópica</span></h1>
            <p data-aos="fade-up" data-aos-delay="100">Nuevo procedimiento de mínima invasión para perder peso</p>
            <div className="d-flex" data-aos="fade-up" data-aos-delay="200">
              {/* <a href="#book-a-table" className="btn-book-a-table">Book a Table</a> */}
            </div>
          </Col>
          <Col lg={5} order={1} className="order-lg-2 text-center text-lg-start">
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default HeroSection;
