import React, { useState } from 'react';
import { Container, Nav } from 'react-bootstrap';
import AlternatingWords from './extras/words';
import '../assets/css/ProcesoSection/styles.css';

function ProcedimientoSection() {

  const words = ['Sin Cirugía', 'Sin tiempos de Recuperación Largos', '1 hora de Procedimiento'];
  const interval = 2000; // Intervalo de 1 segundo entre palabras
  const classNames = ['text-primary h4 opacity-75', 'text-primary h4 opacity-75', 'text-primary h4 opacity-75'];

  // Estado para controlar la pestaña activa
  const [activeTab, setActiveTab] = useState('valoracion');

  // Función para manejar el cambio de pestaña
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  // Función para manejar el evento de scroll
  const handleScroll = () => {
    const sections = document.querySelectorAll(".tab-pane");
    const scrollPosition = window.scrollY + window.innerHeight / 2;

    sections.forEach((section) => {
      const sectionTop = section.offsetTop;
      const sectionHeight = section.clientHeight;

      if (scrollPosition >= sectionTop && scrollPosition < sectionTop + sectionHeight) {
        setActiveTab(section.id);
      }
    });
  };

  return (
    <section id="proceso" className="proceso section-proceso" onScroll={handleScroll}>
      <Container data-aos="fade-up">
        <div>
          <h2 data-aos="fade-up" className='text-proceso'>¿Cómo es el proceso de colocación de la<span className='text-primary'><br></br>MANGA GÁSTRICA Endoscópica</span>?</h2>
        </div>
      </Container>
      <AlternatingWords words={words} interval={interval} classNames={classNames} />
      <Container data-aos="fade-up" className='mt-5'>
        <Nav className="nav nav-tabs d-flex justify-content-center" data-aos="fade-up" data-aos-delay="200">
          <Nav.Item>
            <Nav.Link onClick={() => handleTabChange('valoracion')} className={activeTab === 'valoracion' ? 'active' : ''}>
              <h4>Valoración</h4>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link onClick={() => handleTabChange('estudios-preoperatorios')} className={activeTab === 'estudios-preoperatorios' ? 'active' : ''}>
              <h4>Estudios Pre Operatorios</h4>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link onClick={() => handleTabChange('preoperatorios')} className={activeTab === 'preoperatorios' ? 'active' : ''}>
              <h4>Indicaciones Preoperatorias</h4>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link onClick={() => handleTabChange('procedimiento')} className={activeTab === 'procedimiento' ? 'active' : ''}>
              <h4>Procedimiento</h4>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link onClick={() => handleTabChange('postoperatorios')} className={activeTab === 'postoperatorios' ? 'active' : ''}>
              <h4>Post Operatorio</h4>
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <div className="tab-content" data-aos="fade-up" data-aos-delay="300">
          <div className={`tab-pane fade ${activeTab === 'valoracion' ? 'active show' : ''}`} id="valoracion">
            <p className='mt-5'>Su consulta de valoración será con el cirujano encargado del procedimiento; el <span className='text-primary'>Dr. Juan Antonio</span> Sanchez Valle, especialista en Cirugía Gastrointetinal y Endoscopía Bariatrica.</p>
          </div>
          <div className={`tab-pane fade ${activeTab === 'estudios-preoperatorios' ? 'active show' : ''}`} id="estudios-preoperatorios">
            <p className='mt-5'>Según sus resultados y la edad se podra programar su procedimiento o debera inicialmente acudir a una consulta de valoracion cardiologica preoperatoria.</p>
          </div>
          <div className={`tab-pane fade ${activeTab === 'preoperatorios' ? 'active show' : ''}`} id="preoperatorios">
            <p className='mt-5'>Acudir a consulta medico nutricional para las indicaciones de dieta pre y pos aplicación de manga gástrica endoscópica durante sus primeros 15 dias.</p>
            <p>Llegar <span className='text-primary'>acompañado</span> con ropa comoda.</p>
            <p>Importante <span className='text-primary'>Ayuno de 8HRS</span></p>
            <p>Firma de consentimiento informado.</p>
          </div>
          <div className={`tab-pane fade ${activeTab === 'procedimiento' ? 'active show' : ''}`} id="procedimiento">
            <p className='mt-5'>Inicialmente se procede a realizar una endoscopia de revisión para descartar condiciones que contraindiquen</p>
            <p>El procedimiento se realiza en una sala de cirugía bajo anestesia general y a través de la boca por vía endoscópica, se avanza y visualiza el estómago realizando unas suturas dentro del mismo, dejándolo en forma de tubo (manga o sleeve) y buscando disminuirlo aproximadamente hasta un 30-50 % de su volumen y capacidad inicial.</p>
          </div>
          <div className={`tab-pane fade ${activeTab === 'postoperatorios' ? 'active show' : ''}`} id="postoperatorios">
            <p className='mt-5'>Los estudios y experiencia sobre la manga gástrica por endoscópia, han demostrado tener un perfil de seguridad favorable con muy bajas complicaciones.</p>
            <p>En el postoperatorio inmediato el paciente permanece en recuperación y se da salida en unas horas o puede permanecer hospitalizado hasta el día siguiente de manera excepcional.</p>
            <p>Puede manifestar leve dolor abdominal epigástrico asociado a distensión ocasional, náuseas y vómito que pueden permanecer durante 2 o 3 días y que se manejan con medicamentos ambulatorios.</p>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default ProcedimientoSection;
