
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../assets/css/DoctorSection/styles.css';
import imageDoctor from '../assets/images/Doctores/cirujano-juan-antonio-sanchez-valle.webp'
import { Link } from 'react-router-dom';



function DoctorSection() {


  return (
    
    <section id="doctor" data-bs-theme="light" className="doctor section-doctor">
      <Container className="spacing-2" data-aos="fade-up">
        <Row className="justify-content-center gy-5">
          <Col lg={6} order={{ xs: 3, lg: 2 }} className="d-flex flex-column justify-content-center align-items-center align-items-lg-start text-center text-lg-start order-xl-1">
          <h2 data-aos="fade-up" className='text-secondary my-4'>Cirujano Especialista<br></br> <span className='text-primary opacity-75'>Juan Antonio Sánchez Valle</span></h2>
            <ul className='text-start unlisted' data-aos="fade-up" data-aos-delay="100">
              <li>Realizó Estudios Universitarios de Pregrado en la Carrera de Medicina</li>
              <li>Título Académico de Médico Cirujano.</li>
              <li>Especialista en Cirugía General, Gastrointestinal y Laparoscópica en Hospital de Especialidades, Centro Médico Nacional Siglo XXI, Instituto Mexicano del Seguro Social. Residencia de 4 años.</li>
              <li>Sub-Especialista en Coloproctología en Hospital General de México - Secretaría de Salud. Residencia de 2 años en la especialidad de Coloproctologia, Endoscopia Bariatrica en BAJA HOSPITAL & MEDICAL CENTER. Tijuana Baja California. Instituto Tecnologico de Monterrey.</li>
              <li>Más de 20 años de experiencia en cirugía gastrointestinal y laparoscópica avanzada y endoscopía digestva y bariática lo certifican.</li>
              <li>Especialista en Endoscopia Bariátrica en BAJA HOSPITAL & MEDICAL CENTER. Tijuana Baja California. Instituto Tecnológico de Monterrey.</li>
              <li>Cirujano Certificado Consejo Mexicano de Cirugia General y Colegio Mexicano de Especialistas en Coloproctologia.</li>
            </ul>
            <div className='text-center'>
              <Link as={Link} to="/Doctor-Juan-Antonio" className='mt-5 btn-doctor'>Ver Curriculum</Link>

            </div>
          </Col>
          <Col lg={6} className="text-center text-lg-start d-flex align-items-center order-0">
            <img src={imageDoctor} className="img-fluid rounded-5" alt="" data-aos="zoom-out" data-aos-delay="300" />
          </Col>
        </Row>
      </Container>     

    </section>
  );
}

export default DoctorSection;

