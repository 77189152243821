import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../assets/css/FooterSection/styles.css';
import CSSImage from '../assets/images/logos/vcss-blue.gif'
import { Link } from 'react-router-dom';

function Footer() {
  return (

    <footer className="bg-footer bg-opacity-75 pt-4">
      <Container fluid>
        <Row className="p-5">
          <Col lg={3} className="col-12 align-left text-center text-md-start">
            <a className="navbar-brand" href="/">
              <span className="h4 text-white fw-bold">Clínica</span><span className="h3 text-white fw-bold"> Medicci</span>
            </a>
          </Col>
          <Col lg={3} md={4} className="my-sm-0 mt-4">
            <h3 className="h4 pb-lg-3 text-white fw-bold text-start">Menú</h3>
            <ul className="list-unstyled text-start">
              <li className="pb-2">
                <i className="bi bi-arrow-90deg-right fa-sm me-3 text-white"></i><a className="text-decoration-none text-white" href="/">Inicio</a>
              </li>
              <li className="pb-2">
                <i className="bi bi-arrow-90deg-right fa-sm me-3 text-white"></i><Link to="/precios" className='text-decoration-none text-white'>Precios</Link>
              </li>
              <li className="pb-2">
                <i className="bi bi-arrow-90deg-right fa-sm me-3 text-white"></i><Link to="/Doctor-Juan-Antonio" className='text-decoration-none text-white'>Dr. Juan Antonio</Link>
              </li>
              <li className="pb-2">
                <i className="bi bi-arrow-90deg-right fa-sm me-3 text-white"></i><Link to="/ubicaciones" className='text-decoration-none text-white'>Ubicaciones</Link>
              </li>
            </ul>
          </Col>
          <Col lg={3} md={4} className="my-sm-0 mt-4 text-start">
            <h4 className="h4 pb-lg-3 text-white fw-bold"><i className="bi bi-sign-turn-right fa-sm me-3 text-white"></i><a className="h5 pb-lg-3 text-white fw-bold text-decoration-none pb-5" href='https://balongastricocdmx.com/' target='_blank' rel='noreferrer'>Balón Intragástrico</a></h4>
            <h4 className="h4 pb-lg-3 text-white fw-bold"><i className="bi bi-sign-turn-right fa-sm me-3 text-white"></i><a className="h5 pb-lg-3 text-white fw-bold text-decoration-none pb-5" href='https://clinica-hernia.com/' target='_blank' rel='noreferrer'>Clínica de Hernias</a></h4>
            <h4 className="h4 pb-lg-3 text-white fw-bold"><i className="bi bi-sign-turn-right fa-sm me-3 text-white"></i><a className="h6 pb-lg-3 text-white fw-bold text-decoration-none pb-5" href='https://unidad-gastroenterologia.com/' target='_blank' rel='noreferrer'>CLÍNICA DE GASTROENTEROLOGÍA</a></h4>
          </Col>
          <Col id="contacto-directo" lg={3} md={4} className="my-sm-0 mt-4">
            <h3 className="h4 pb-lg-3 text-white fw-bold">Mapa de sitio</h3>
            <ul className="list-unstyled">
              <li className="pb-2">
                <i className="bi bi-arrow-90deg-right fa-sm me-3 text-white"></i><Link as={Link} to="/Doctor-Juan-Antonio" className='mt-5 text-decoration-none fw-bold'>Dr. Juan Antonio S.V.</Link>
              </li>
              <li className="pb-2">
                <i className="bi bi-arrow-90deg-right fa-sm me-3 text-white"></i><Link to="/sitemap.xml" className='text-decoration-none text-white'>SiteMap</Link>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <div className="bg-segundo py-1">
        <Container py={1}>
          <Row className="align-items-center justify-content-center">
            <Col lg={6} order={3} order-md={0}>
              <p className="text-lg-start text-white-50 d-grid d-md-block">
                Copyright © 2024 Clínica Medicci - Todos los derechos reservados.<br />
                <Link to="/Aviso-Privacidad" className='text-white-50 mx-auto py-2'>Aviso de Privacidad</Link>
              </p>
            </Col>
            <Col sm={3} lg={3} order={2} order-md={2} className="d-none d-md-block">
              <p className="mx-auto">
                <a href="https://jigsaw.w3.org/css-validator/check/referer">
                  <img className="w3schoools" src={CSSImage} width="90" height="30" alt="¡CSS Válido!" />
                </a>
              </p>
            </Col>
            <Col sm={3} lg={3} order={0} order-md={3} py={3}>
            <p className="text-lg-end text-center text-white-50"><i className="bi bi-code-slash fa-sm me-3"></i>Designed by <a target="_blank" rel="noreferrer" className="text-decoration-none text-white fw-bold" href="https://api.whatsapp.com/send?phone=+525538139033&text=Dise%C3%B1o%20Web">PakinRey</a></p>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
}

export default Footer;
