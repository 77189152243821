import React from 'react';

function DividerInvertedSection() {
  return (
    <svg width="3032" height="215" viewBox="0 0 3032 215" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform="rotate(180 1516 107.5)">
        <g clipPath="url(#clip0_2361_6024)">
          <path d="M-12972.6 723.315C-12486.1 853.683 -11789.8 974.745 -10324.6 897.694C-8859.4 820.642 -8839.31 697.802 -7831.12 786.884C-6822.92 875.965 -6760.66 1451.53 -6064.5 1604.19C-5368.35 1756.85 -3539.76 1553.96 -2793.21 1404.8C-2046.66 1255.63 -455.775 991.11 417.109 1112.42C1289.99 1233.72 1679.23 1691.98 3924.1 1864C6168.97 2036.02 8123.32 1637.41 8766 1360.33C9408.68 1083.26 9181.29 650.798 7161.74 268.1C5142.2 -114.597 2668.12 60.9495 1479.05 86.5162C289.984 112.083 -431.946 86.5162 -1055.32 -25.5068C-1799.1 -210.923 -3611.4 -392.982 -4918.06 -190.453C-6224.71 12.0766 -7084.8 -284.163 -7433.13 18.2072C-7781.46 320.578 -8825.68 11.1192 -10240.4 -62.225C-11655.1 -135.569 -12569.7 -113.372 -13362.5 104.11C-14155.4 321.593 -13410.2 605.877 -12972.6 723.315Z" fill="var(--color-bg)"/>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2361_6024">
          <rect width="3032" height="215" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
}

export default DividerInvertedSection;
