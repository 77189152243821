import React from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import '../assets/css/PreciosSection/styles.css';
import IconoConsulta from '../assets/images/Iconos/consulta-icono.webp'
import IconoEstudios from '../assets/images/Iconos/Estudios-icono.webp'

function PreciosSection() {
  return (
    <>
          <Row className='justify-content-center'>
            <Col lg={4}>
              <Card className="mb-5 mb-lg-0">
                <Card.Body>
                  <img className='img-fluid image-precios' src={IconoConsulta} alt="Icono referente a Consulta Medica" />
                  <Card.Title className="text-primary text-uppercase text-center fs-5 mb-3">Consulta de Valoración</Card.Title>
                  <Card.Subtitle className="text-center fs-4 text-primary mb-3">$1,400 MXN</Card.Subtitle>
                  <Card.Subtitle className="text-center fs-4 text-primary mb-2">Pago en Efectivo</Card.Subtitle>
                  <Card.Subtitle className="text-center fs-4 text-primary">$1,000 <span className="period">MXN</span></Card.Subtitle>
                  <hr />
                  <ul className="list-group">
                    <li className='p-3 p-lg-4'><span className="fa-li"><i className="fas fa-check"></i></span>Consulta con Cirujano</li>
                  </ul>
                  <div className="d-grid">
                    <Button variant="primary" className="text-uppercase">Agendar Cita!</Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={4}>
              <Card className="mb-5 mb-lg-0">
                <Card.Body>
                  <img className='img-fluid image-precios' src={IconoEstudios} alt="Icono referente a Estudio Medico" />
                <Card.Title className="text-primary text-uppercase text-center fs-5">Estudios de Valoración</Card.Title>
                  <Card.Subtitle className="text-muted mb-3 text-decoration-line-through"><span className="fa-li"><i className="fas fa-times"></i></span>$12,000 MXN</Card.Subtitle>
                  <Card.Subtitle className="text-center fs-4 text-primary">$10,000 <span className="period">MXN</span></Card.Subtitle>
                  <hr />
                  <ul className="list-group">
                    <li><span className="fa-li"><i className="fas fa-check"></i></span>Endoscopía Digestiva Alta</li>
                    <li><span className="fa-li"><i className="fas fa-check"></i></span>Biopsia de Estomago</li>
                    <li><span className="fa-li"><i className="fas fa-check"></i></span>Estudios Preoperatorios</li>
                    <li><span className="fa-li"><i className="fas fa-check"></i></span>Valoración preoperatoria cardiológica</li>
                  </ul>
                  <div className="d-grid">
                    <Button variant="primary" className="text-uppercase">Agendar Cita!</Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
    </>
  );
}

export default PreciosSection;
