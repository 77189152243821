import React from 'react';
import { Container, Row, Col,} from 'react-bootstrap';
import '../assets/css/NotFoundSection/styles.css';
import { Link } from 'react-router-dom';

function NotFoundSection() {

  return (
    <section id="hero" className="hero d-flex align-items-center section-bg backgroundHero">
      <Container>
        <Row className="justify-content-between gy-5">
          <Col lg={5} order={2} className="order-lg-1 d-flex flex-column justify-content-center align-items-center align-items-lg-start text-center text-lg-start">
            <h1 data-aos="fade-up"><br />Esta Pagina <span className='text-primary'>NO Existe</span></h1>
            <p data-aos="fade-up" data-aos-delay="100">Regresa al Inicio</p>
            <div className="d-flex" data-aos="fade-up" data-aos-delay="200">
              <Link className='boton-regreso' as={Link} to="/">Inicio</Link>
            </div>
          </Col>
          <Col lg={5} order={1} className="order-lg-2 text-center text-lg-start">
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default NotFoundSection;
