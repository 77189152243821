import React from 'react';
// import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { Container } from 'react-bootstrap';
import '../assets/css/AvisoPriv/styles.css';
import ContactSection from '../components/Contact';

function AvisoPrivPage() {
  return (
    <>
    <Helmet>
      <title>Precios de Manga Gástrica Endoscopica</title>
      <meta name="description" content="Consulta los precios de nuestros procedimientos de manga gástrica endoscópica." />
      <meta name="keywords" content="Precios, Manga gástrica, Cirugía bariátrica" />
    </Helmet>
      <section className="Section-Aviso-Priv px-5">
        <Container>
          <h1>Aviso de privacidad</h1>
          <p className="intro">
            En cumplimiento con la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (la "Ley") y su reglamento, se emite el presente aviso de privacidad en los términos siguientes:
          </p>
          <h2>Responsable del tratamiento de los datos personales</h2>
          <p>
            Torre Medica Medicci S.A. de C.V., con domicilio en: Insurgentes Sur 1809, Piso 4, Guadalupe Inn, CDMX, México, CP: 01020 y dirección de correo electrónico&nbsp;
            <a target="_blank" rel='noreferrer' className="text-decoration-none text-primary py-1" href="mailto:medica.medicci@gmail.com">medica.medicci@gmail.com</a>.
          </p>
          <h2>Declaración de privacidad</h2>
          <div className="intro">
            <p>
              En <b>Torre Medica Medicci</b> nos preocupamos por la protección de tus datos personales y el cumplimiento de la normativa en materia de privacidad. Por ello, te proporcionamos esta información para que sepas cómo recabamos y tratamos tus datos personales.
            </p>
          </div>
          <h2>Información recabada</h2>
          <p>Los datos personales que recabamos pueden incluir:</p>
          <ul>
            <li>Nombre completo</li>
            <li>Correo electrónico</li>
            <li>Número telefónico</li>
            <li>Edad</li>
            <li>Sexo</li>
            <li>Peso</li>
            <li>Altura</li>
          </ul>
          <p>
            Estos datos son necesarios para poder ofrecerte nuestros servicios de colocación de balón intragástrico. No recabamos datos personales sensibles sin previo consentimiento.
          </p>
          <h2>Finalidades del tratamiento</h2>
          <p>Los datos personales que recabamos son utilizados para las siguientes finalidades:</p>
          <ul>
            <li>Brindarte información sobre nuestros servicios</li>
            <li>Realizar la colocación del balón intragástrico</li>
            <li>Realizar el seguimiento de tu tratamiento</li>
            <li>Realizar facturación y cobro de nuestros servicios</li>
            <li>Realizar estadísticas y análisis de mercado</li>
          </ul>
          <h2>Transferencias de datos</h2>
          <p>No compartimos tus datos personales con terceros, salvo que sea necesario para la prestación de nuestros servicios o que la ley nos obligue a hacerlo.</p>
          <h2>Derechos ARCO</h2>
          <p>
            Como titular de los datos personales que recabamos, tienes derecho a acceder a ellos, rectificarlos, cancelarlos u oponerte a su tratamiento. Para ejercer estos derechos, por favor contáctanos a través de&nbsp;
            <a className="contacto_correo" href="mailto:medica.medicci@gmail.com">medica.medicci@gmail.com</a> o por medio del botón de WhatsApp disponible en este sitio web.
          </p>
          <h2>Cambios al aviso de privacidad</h2>
          <p>
            Nos reservamos el derecho de efectuar en cualquier momento modificaciones o actualizaciones al presente aviso de privacidad, para la atención de novedades legislativas, jurisprudenciales, políticas internas, nuevos requerimientos para la prestación u ofrecimiento de nuestros servicios o productos y prácticas del mercado. Cualquier modificación al presente aviso de privacidad se podrá consultar en este sitio web.
          </p>
          <h2>Aceptación de los términos</h2>
          <p>Al utilizar este sitio web, el cliente acepta los términos y condiciones del presente aviso de privacidad.</p>
          <h2>Contacto</h2>
          <p>
            Si tienes alguna duda o comentario sobre el tratamiento de tus datos personales o sobre el presente aviso de privacidad, por favor contáctanos a través de&nbsp;
            <a className="contacto_correo" href="mailto:medica.medicci@gmail.com">medica.medicci@gmail.com</a> o por medio del botón de WhatsApp disponible en este sitio web.
          </p>
        </Container>
      </section>
      <ContactSection/>
    </>
  );
}

export default AvisoPrivPage;
