import React, { useState, Suspense, lazy } from 'react';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import '../assets/css/ContactSection/styles.css';
import { locations } from './extras/Direcciones';

const Map = lazy(() => import('./Map'));

function ContactSection() {
  const [activeTab, setActiveTab] = useState('menu1');

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const whatsappNumber = locations.location1.whatsapp; // Número de WhatsApp
    const messageBody = `Nombre: ${name}%0AEmail: ${email}%0AAsunto: ${subject}%0AMensaje: ${message}`;
    window.open(`https://wa.me/${whatsappNumber}?text=${messageBody}`, '_blank');
  };

    const activeLocation = locations[`location${activeTab.replace('menu', '')}`];
  

  return (
    <section id="contact" className="contact">
      <Container data-aos="fade-up">
        <div>
          <h3 data-aos="fade-up" className='text-secondary h1'>
            ¿Tienes dudas?<span className='text-primary'><br></br>¡Contáctanos!</span>
          </h3>
        </div>
      </Container>
      <Container data-aos="fade-up" className='mt-5'>
        <Nav className="nav nav-tabs d-flex justify-content-center" data-aos="fade-up" data-aos-delay="200">
          <Nav.Item>
            <Nav.Link
              onClick={() => handleTabChange('menu1')}
              className={activeTab === 'menu1' ? 'active' : ''}
            >Medicci Insurgentes Sur</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              onClick={() => handleTabChange('menu2')}
              className={activeTab === 'menu2' ? 'active' : ''}
            >Medicci Condesa</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              onClick={() => handleTabChange('menu3')}
              className={activeTab === 'menu3' ? 'active' : ''}
            >Medicci Zona de Hospitales</Nav.Link>
          </Nav.Item>
        </Nav>
        <div className="tab-content" data-aos="fade-up" data-aos-delay="300">
          <div className={`tab-pane fade ${activeTab === 'menu1' ? 'show active' : ''}`}>
            <Row className="gy-4 mt-3">
              <Col md={6}>
                <div className="info-item d-flex align-items-center">
                  <i className="bi bi-map flex-shrink-0 icon"></i>
                  <div>
                    <h3>Dirección</h3>
                    <p>{activeLocation.address}</p>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="info-item d-flex align-items-center">
                  <i className="bi bi-envelope flex-shrink-0 icon"></i>
                  <div>
                    <h3>Email</h3>
                    <p>{activeLocation.email}</p>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="info-item d-flex align-items-center justify-content-center">
                  <div>
                    <h3>Teléfonos</h3>
                    <div className='d-flex align-items-center'><i className="bi bi-telephone flex-shrink-0 icon-secondary icon-blue"></i><a className="text-decoration-none fs-4 ms-2" href={`tel:${activeLocation.phone}`}>+52 {activeLocation.phone}</a></div>
                    <div className='d-flex align-items-center mt-3'>
                      <i className="bi bi-whatsapp flex-shrink-0 icon-secondary icon-green"></i>
                      <a
                        href={`https://api.whatsapp.com/send?phone=52${activeLocation.whatsapp}`}
                        className="fs-4 ms-2 text-decoration-none"
                        target="_blank"
                        rel="noreferrer">+52 {activeLocation.whatsapp}</a>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="info-item d-flex align-items-center">
                  <i className="bi bi-share flex-shrink-0 icon"></i>
                  <div>
                    <h3>Horario de Trabajo</h3>
                    <div>
                      <strong>Lunes a Sábado:</strong> 8AM - 10PM; <strong>Domingo:</strong> Cerrado
                    </div>
                  </div>
                </div>
              </Col>
              <Col>
                {activeTab === 'menu1' && (
                  <Suspense fallback={<div>Cargando mapa...</div>}>
                    <Map src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d235.26775114840035!2d-99.18472500000001!3d19.356851!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1fff479106075%3A0x225b7743e5e0d0b5!2sMEDICCI%20Unidad%20de%20Gastroenterolog%C3%ADa!5e0!3m2!1ses!2sus!4v1714846621873!5m2!1ses!2sus" />
                  </Suspense>
                )}
              </Col>
            </Row>
          </div>
          <div className={`tab-pane fade ${activeTab === 'menu2' ? 'show active' : ''}`}>
            <Row className="gy-4 mt-3">
              <Col md={6}>
                <div className="info-item d-flex align-items-center">
                  <i className="bi bi-map flex-shrink-0 icon"></i>
                  <div>
                    <h3>Dirección</h3>
                    <p>{activeLocation.address}</p>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="info-item d-flex align-items-center">
                  <i className="bi bi-envelope flex-shrink-0 icon"></i>
                  <div>
                    <h3>Email</h3>
                    <p>{activeLocation.email}</p>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="info-item d-flex align-items-center justify-content-center">
                  <div>
                    <h3>Teléfonos</h3>
                    <div className='d-flex align-items-center'><i className="bi bi-telephone flex-shrink-0 icon-secondary icon-blue"></i><a className="text-decoration-none fs-4 ms-2" href={`tel:${activeLocation.phone}`}>+52 {activeLocation.phone}</a></div>
                    <div className='d-flex align-items-center mt-3'>
                      <i className="bi bi-whatsapp flex-shrink-0 icon-secondary icon-green"></i>
                        <a
                          href={`https://api.whatsapp.com/send?phone=52${activeLocation.whatsapp}`}
                          className="fs-4 ms-2 text-decoration-none"
                          target="_blank"
                          rel="noreferrer">+52 {activeLocation.whatsapp}</a>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="info-item d-flex align-items-center">
                  <i className="bi bi-share flex-shrink-0 icon"></i>
                  <div>
                    <h3>Horario de Trabajo</h3>
                    <div>
                      <strong>Lunes a Sábado:</strong> 8AM - 10PM; <strong>Domingo:</strong> Cerrado
                    </div>
                  </div>
                </div>
              </Col>
              <Col>
                {activeTab === 'menu2' && (
                  <Suspense fallback={<div>Cargando mapa...</div>}>
                    <Map src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d235.193782946831!2d-99.17257604174053!3d19.408062034771806!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1fff602026717%3A0x3be1126e6f314f52!2sMedicci%20Condesa%2C%20Unidad%20de%20Gastroenterolog%C3%ADa%20y%20Endoscopia%20Digestiva!5e0!3m2!1ses-419!2smx!4v1715483335410!5m2!1ses-419!2smx" />
                  </Suspense>
                )}
              </Col>
            </Row>
          </div>
          <div className={`tab-pane fade ${activeTab === 'menu3' ? 'show active' : ''}`}>
            <Row className="gy-4 mt-3">
              <Col md={6}>
                <div className="info-item d-flex align-items-center">
                  <i className="bi bi-map flex-shrink-0 icon"></i>
                  <div>
                    <h3>Dirección</h3>
                    <p>{activeLocation.address}</p>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="info-item d-flex align-items-center">
                  <i className="bi bi-envelope flex-shrink-0 icon"></i>
                  <div>
                    <h3>Email</h3>
                    <p>{activeLocation.email}</p>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="info-item d-flex align-items-center justify-content-center">
                  <div>
                    <h3>Teléfonos</h3>
                    <div className='d-flex align-items-center'><i className="bi bi-telephone flex-shrink-0 icon-secondary icon-blue"></i><a className="text-decoration-none fs-4 ms-2" href={`tel:${activeLocation.phone}`}>+52 {activeLocation.phone}</a></div>
                    <div className='d-flex align-items-center mt-3'>
                      <i className="bi bi-whatsapp flex-shrink-0 icon-secondary icon-green"></i>
                      <a
                        href={`https://api.whatsapp.com/send?phone=52${activeLocation.whatsapp}`}
                        className="fs-4 ms-2 text-decoration-none"
                        target="_blank"
                        rel="noreferrer">+52 {activeLocation.whatsapp}</a>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="info-item d-flex align-items-center">
                  <i className="bi bi-share flex-shrink-0 icon"></i>
                  <div>
                    <h3>Horario de Trabajo</h3>
                    <div>
                      <strong>Lunes a Sábado:</strong> 8AM - 10PM; <strong>Domingo:</strong> Cerrado
                    </div>
                  </div>
                </div>
              </Col>
              <Col>
                {activeTab === 'menu3' && (
                  <Suspense fallback={<div>Cargando mapa...</div>}>
                    <Map src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d235.35694204232857!2d-99.1562328!3d19.2949269!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85ce005766e2ae35%3A0x3b037f8c0652f7e7!2sMedicci%2C%20Tlalpan%20Zona%20de%20Hospitales!5e0!3m2!1ses-419!2smx!4v1715456127773!5m2!1ses-419!2smx" />
                  </Suspense>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Container>

      {/* Información de contacto */}
      <Container data-aos="fade-up" className='mt-5'>
        <form onSubmit={handleSubmit} className="php-email-form p-3 p-md-4">
          <Row>
            <Col xl={6} className="form-group">
              <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="form-control" placeholder="Tu Nombre" required />
            </Col>
            <Col xl={6} className="form-group">
              <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder="Tu Email" required />
            </Col>
          </Row>
          <Row>
            <Col xl={6} className="form-group">
              <input type="text" value={subject} onChange={(e) => setSubject(e.target.value)} className="form-control" placeholder="Asunto" required />
            </Col>
            <Col xl={6} className="form-group">
              <input type="text" className="form-control" placeholder="5+11" required />
            </Col>
          </Row>
          <div className="form-group">
            <textarea value={message} onChange={(e) => setMessage(e.target.value)} className="form-control" rows="5" placeholder="Mensaje" required></textarea>
          </div>
          <div className="my-3">
            <div className="loading">Cargando</div>
            <div className="error-message"></div>
            <div className="sent-message">¡Tu mensaje fue enviado con éxito!</div>
          </div>
          <div className="text-center">
            <button type="submit">Enviar Mensaje</button>
          </div>
        </form>
      </Container>
    </section>
  );
}

export default ContactSection;
