import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function levenshtein(a, b) {
  const tmp = [];

  if (a.length === 0) return b.length;
  if (b.length === 0) return a.length;

  for (let i = 0; i <= b.length; i++) {
    tmp[i] = [i];
  }

  for (let j = 0; j <= a.length; j++) {
    tmp[0][j] = j;
  }

  for (let i = 1; i <= b.length; i++) {
    for (let j = 1; j <= a.length; j++) {
      tmp[i][j] = b.charAt(i - 1) === a.charAt(j - 1) ?
        tmp[i - 1][j - 1] : 
        Math.min(tmp[i - 1][j - 1] + 1, Math.min(tmp[i][j - 1] + 1, tmp[i - 1][j] + 1));
    }
  }

  return tmp[b.length][a.length];
}

function findClosestRoute(path, routes) {
  let closestRoute = routes[0];
  let minDistance = levenshtein(path, routes[0]);

  for (let i = 1; i < routes.length; i++) {
    const distance = levenshtein(path, routes[i]);
    if (distance < minDistance) {
      minDistance = distance;
      closestRoute = routes[i];
    }
  }

  return { closestRoute, minDistance };
}

const routes = ["/", "/precios", "/ubicaciones", "/Doctor-Juan-Antonio"];
const DISTANCE_THRESHOLD = 3; // Define un umbral adecuado

function Redirector() {
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    const path = location.pathname;
    const { closestRoute, minDistance } = findClosestRoute(path, routes);
    if (minDistance > DISTANCE_THRESHOLD) {
      navigate('/404', { replace: true });
    } else {
      navigate(closestRoute, { replace: true });
    }
  }, [location, navigate]);

  return null;
}

export default Redirector;
