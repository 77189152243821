//modal.jsx
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import '../../assets/css/modal/styles.css'

const VideoModal = ({ show, handleClose, videoId, title }) => {
  const videoUrl = `https://www.youtube.com/embed/${videoId}?autoplay=1`;

  return (
    <Modal show={show} onHide={handleClose} dialogClassName="custom-modal">
      <iframe 
        className="custom-iframe"
        height="320px"
        width="100%" 
        src={videoUrl} 
        title={title}
        frameBorder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
        referrerPolicy="strict-origin-when-cross-origin" 
        allowFullScreen
      ></iframe>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
        <i className="bi bi-x-lg"></i>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default VideoModal;
