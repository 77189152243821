import React from 'react';
import { Helmet } from 'react-helmet-async';
import HeroSection from '../components/HeroSection';
import InformacionSection from '../components/InformacionSection';
import ProcedimientoSection from '../components/Procedimiento';
import CandidatosSection from '../components/CandidatosSection';
import ComparativaSection from '../components/Comparativa';
import DoctorSection from '../pages/DoctorSeccion';
import PreciosSection from '../components/Precios';
import { Container} from 'react-bootstrap';
import ContactSection from '../components/Contact';
import '../assets/css/PreciosSection/styles.css';

const HomePage = () => (
  <>
    <Helmet>
      <title>Manga Gástrica Endoscopica - Inicio</title>
      <meta name="description" content="Descubre la manga gástrica endoscópica en nuestra página principal." />
      <meta name="keywords" content="Manga gástrica, Home, Página principal" />
      <link rel="canonical" href="https://www.mangagastricaendoscopica.mx/" />
    </Helmet>
    <header className="App-header">
      <HeroSection />
    </header>
    <InformacionSection />
    <ProcedimientoSection />
    <CandidatosSection />
    <ComparativaSection />
    <section className='pricing px-5'>
      <Container data-aos="fade-up">
          <div className='my-5'>
            <h1 data-aos="fade-up" className='text-white mb-5'>¿Que costo tiene la <span className='text-primary'>MANGA GÁSTRICA Endoscópica</span>?</h1>
            <p className='text-white fs-5'>El costo de la Manga Gástrica Endoscópica varía según el tipo de paciente y sus necesidades específicas.</p>
            <p className='text-white fs-5'>Por lo general, se determina después de la consulta de valoración.</p>
          </div>
        </Container>
        <Container>
          <PreciosSection />
        </Container>
    </section>
    <DoctorSection />
    <ContactSection />
  </>
);

export default HomePage;
