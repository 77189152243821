import React, { useState } from 'react';
import VideoModal from '../modal/modal';
// import '../../assets/css/ButtonModal/styles.css'

function VideoButton({ videoId, title }) {
    const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;
    const [showModal, setShowModal] = useState(false);

    const handleOpenModal = (event) => {
        event.preventDefault();
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    const videoButtonStyle = {
        width: '100%',
        maxWidth: '600px',

    }

    return (
        <>
            <div onClick={handleOpenModal}>
                {/* <img src="https://assets-global.website-files.com/637cbc505a94a1c729b8030f/63f39d12e2e9a26059d00aaf_hero-image.png" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 60vw, (max-width: 991px) 59vw, (max-width: 1439px) 50vw, 53vw" srcset="https://assets-global.website-files.com/637cbc505a94a1c729b8030f/63f39d12e2e9a26059d00aaf_hero-image-p-500.png 500w, https://assets-global.website-files.com/637cbc505a94a1c729b8030f/63f39d12e2e9a26059d00aaf_hero-image-p-800.png 800w, https://assets-global.website-files.com/637cbc505a94a1c729b8030f/63f39d12e2e9a26059d00aaf_hero-image-p-1080.png 1080w, https://assets-global.website-files.com/637cbc505a94a1c729b8030f/63f39d12e2e9a26059d00aaf_hero-image.png 1600w" alt="osobní lékařky a specialisté health plus" class="rl_header1_image"></img> */}
                <img role='button' width='400px' style={videoButtonStyle} src={thumbnailUrl} alt={`Video ${title}`} className="img-responsive rounded-5 imageVideo" />
                <div role='button' className="play-btn"></div>
            </div>

            <VideoModal
                show={showModal}
                handleClose={handleCloseModal}
                videoId={videoId}
                title={`Video ${title}`}
            />
        </>
    );
}

export default VideoButton;
