import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Container} from 'react-bootstrap';
import '../assets/css/PreciosSection/styles.css';
import PreciosSection from '../components/Precios';
import ContactSection from '../components/Contact';

function PreciosPage() {
  return (
    <>
      <Helmet>
        <title>Precios de Manga Gástrica Endoscopica</title>
        <meta name="description" content="Consulta los precios de nuestros procedimientos de manga gástrica endoscópica." />
        <meta name="keywords" content="Precios, Manga gástrica, Cirugía bariátrica" />
        <meta name="price" content="A partir de los $90,000 MXN" />
      </Helmet>
      <section className="pricing px-5">
        <Container data-aos="fade-up">
          <div className='my-5'>
            <h1 data-aos="fade-up" className='text-white mb-5'>¿Que costo tiene la <span className='text-primary'>MANGA GÁSTRICA Endoscópica</span>?</h1>
            <p className='text-white fs-5'>El costo de la Manga Gástrica Endoscópica varía según el tipo de paciente y sus necesidades específicas. Por lo general, se determina después de la consulta de valoración. El precio inicial suele estar alrededor de los 90,000 pesos mexicanos.</p>
            <p className='text-white fs-5'>Si deseas más información o agendar una cita, ¡contáctanos! Estamos aquí para ayudarte.</p>
            <p></p>
          </div>
        </Container>
        <Container>
          <PreciosSection />
        </Container>
      </section>
      <ContactSection></ContactSection>
    </>
  );
}

export default PreciosPage;
