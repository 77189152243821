
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
// import AlternatingWords from './extras/words';
import '../assets/css/comparativaSection/styles.css';
import DividerInvertedSection from './extras/dividerInverted';

function ComparativaSection() {

  // const words = ['Sin Cirugía', 'Sin tiempos de Recuperación Largos', '1 hora de Procedimiento'];
  // const interval = 2000; // Intervalo de 1 segundo entre palabras
  // const classNames = ['text-primary h4 opacity-75', 'text-primary h4 opacity-75', 'text-primary h4 opacity-75']; // Clases CSS para cada palabra 

  return (
    
    <section id="comparativa" className="comparativa section-comparativa">
      <DividerInvertedSection></DividerInvertedSection>
      <Container data-aos="fade-up" className='p-3'>
        <Row>
          <div className="d-flex align-items-center justify-content-center flex-column SectionInfo">
            <h2 data-aos="fade-up" className='text-secondary'>Tabla Comparativa<br></br> <span className='text-primary opacity-75'>con otros procedimientos de reduccion de Peso</span></h2>
            {/* <AlternatingWords words={words} interval={interval} classNames={classNames} /> */}
            <Row className='justify-content-center p-2'>
                <Col md={3} className='tableInfo-red m-4' data-aos="fade-up" data-aos-delay="100">
                  <h5>Balón Gástrico</h5>
                  <p>Tiempo del Procedimiento</p>
                    <ul>
                      <li>40 mins</li>
                    </ul>
                  <p className='mt-3'>Tiempo de Recuperación</p>
                    <ul>
                      <li>1 - 1.5 hrs</li>
                    </ul>
                  <p className='mt-3'>Pérdida de Peso a 1 Año</p>
                    <ul>
                      <li>14 - 22 kgs</li>
                    </ul>
                  <p className='mt-3'>Costo (MXN) **</p>
                    <ul>
                      <li>48 mil - 80 mil</li>
                    </ul>
                </Col>
                <Col md={4} className='tableInfo m-4' data-aos="fade-up" data-aos-delay="400">
                  <h5>Manga Gástrica Endoscópica</h5>
                  <p>Tiempo del Procedimiento</p>
                    <ul>
                      <li>1 hr</li>
                    </ul>
                  <p className='mt-3'>Tiempo de Recuperación</p>
                    <ul>
                      <li>3 - 6 hrs</li>
                    </ul>
                  <p className='mt-3'>Pérdida de Peso a 1 Año</p>
                    <ul>
                      <li>15 - 40 kgs</li>
                    </ul>
                  <p className='mt-3'>Costo (MXN) **</p>
                    <ul>
                      <li>120 mil - 130 mil</li>
                    </ul>
                </Col>
                <Col md={3} className='tableInfo-red m-4' data-aos="fade-up" data-aos-delay="200">
                  <h5>Manga Gástrica<span className='text-primary opacity-75'> Regular</span></h5>
                  <p>Tiempo del Procedimiento</p>
                    <ul>
                      <li>2 hrs</li>
                    </ul>
                  <p className='mt-3'>Tiempo de Recuperación</p>
                    <ul>
                    <li>1 - 2 días</li>
                    </ul>
                  <p className='mt-3'>Pérdida de Peso a 1 Año</p>
                    <ul>
                      <li>20 - 40 kgs</li>
                    </ul>
                  <p className='mt-3'>Costo (MXN) **</p>
                    <ul>
                      <li>80 mil - 180 mil</li>
                    </ul>
                </Col>
            </Row>
            <p>* El tiempo de recuperación por persona es variable.</p>
            <p>** Puede haber gastos adicionales.</p>
          </div>
        </Row>
      </Container>
    </section>
  );
}

export default ComparativaSection;

