import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import logo from '../assets/images/logos/logomedicci-300px.svg'; 
import '../assets/css/navbar/styles.css';
import DarkButton from './button/dark';

const NavBar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleNavLinkClick = () => {
    setExpanded(false);
  };

  const handleToggle = () => {
    setExpanded(expanded ? false : "expanded");
  };

  const toggleScroll = () => {
    setScrolled(window.scrollY > 50);
  };

  return (
    <Navbar sticky="top" bg={scrolled ? "light" : "dark"} expand="lg" className={scrolled ? 'scrolled' : ''} expanded={expanded}>
      <Container>
        <Navbar.Brand as={Link} to='/' onClick={handleNavLinkClick}>
          <img
            src={logo}
            height="30"
            alt="Logo Medicci"
            className='logo'
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={handleToggle} />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className={scrolled ? 'me-auto' : 'me-auto'}>
            <Nav.Link as={Link} to="/" className='nav-link-menu' onClick={handleNavLinkClick}>Inicio</Nav.Link>
            <Nav.Link as={Link} to="/precios" className='nav-link-menu' onClick={handleNavLinkClick}>Precios</Nav.Link>
            <NavDropdown className='dropdown-item' title="Información Extra" id="collapsible-nav-dropdown">
              <NavDropdown.Item as={Link} to="/NotFoundSection" className='nav-link-menu' onClick={handleNavLinkClick}>Riesgos</NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav className={scrolled ? 'me-auto' : 'me-auto'}>
            <Nav.Link as={Link} to="/Doctor-Juan-Antonio" className='nav-link-menu' onClick={handleNavLinkClick}>Dr. Juan Antonio</Nav.Link>
            <Nav.Link eventKey={2} as={Link} to="/ubicaciones" className='nav-link-menu' onClick={handleNavLinkClick}>Ubicaciones</Nav.Link>
          </Nav>
          <DarkButton toggleScroll={toggleScroll} handleNavLinkClick={handleNavLinkClick}></DarkButton>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
