import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../assets/css/CandidatosSection/styles.css';


function CandidatosSection() {

  return (
    <section id="why-us" className="why-us section-bg my-5">
      <Container data-aos="fade-up">
        <Row className="gy-4">
            <Col lg={4} data-aos="fade-up" data-aos-delay="100">
                <div className="why-box why-us-blue">
                    <h3>¿Cómo saber si soy candidato?</h3>
                    <p className='fw-bold fs-4'>Si tienes alguna de estas condiciones:</p>
                    <div className="text-center">
                        <i className="bi bi-person-fill-check fs-2"></i>
                    </div>
                </div>
            </Col>
            <Col lg={8} className="d-flex align-items-center">
                <Row className="gy-4">
                    <Col xl={4} data-aos="fade-up" data-aos-delay="200">
                        <div className="icon-box d-flex flex-column justify-content-center align-items-center border-blue">
                            <i className="bi bi-clipboard-data"></i>
                            <h4>IMC Mayor a 30</h4>
                            <p>La gastroplastia endoscópica en manga se ofrece a personas con un IMC superior a 30 para ayudarlos a perder peso rápidamente.</p>
                            <a href="#index" className="btn bg-primary">Saber mi IMC</a>
                        </div>
                    </Col>
                    <Col xl={4} data-aos="fade-up" data-aos-delay="300">
                        <div className="icon-box d-flex flex-column justify-content-center align-items-center border-blue">
                            <i className="bi bi-heart-pulse-fill"></i>
                            <h4>Obesidad Grado I, II, III</h4>
                            <p>La gastroplastia endoscópica en manga también es una opción para personas con obesidad de grado I, II o III.</p>
                        </div>
                    </Col>
                    <Col xl={4} data-aos="fade-up" data-aos-delay="400">
                        <div className="icon-box d-flex flex-column justify-content-center align-items-center border-blue">
                            <i className="bi bi-emoji-tear-fill"></i>
                            <h4>No calificar para cirugía bariátrica tradicional o no quererla</h4>
                            <p>Es una buena opción cuando alguien no califica para una cirugía bariátrica tradicional o prefiere evitarla debido al riesgo quirúrgico.</p>
                        </div>
                    </Col>
                </Row>
            </Col>

        </Row>
        <Row className="gy-4 mt-5">
        <Col lg={4} data-aos="fade-up" data-aos-delay="100" className="order-first order-lg-1">
            <div className="why-box why-us-red">
              <h3>¿Quién NO es candidato?</h3>
              <p className='fw-bold fs-4'>Pacientes que padezcan de:</p>
              <div className="text-center">
                 <i className="bi bi-person-fill-dash fs-2"></i>
              </div>
            </div>
          </Col>
          <Col lg={8} className="d-flex align-items-center">
            <Row className="gy-4">
              <Col xl={4} data-aos="fade-up" data-aos-delay="200" className="order-4">
                <div className="icon-box d-flex flex-column justify-content-center align-items-center border-red">
                  <i className="bi bi bi-ban"></i>
                  <h4>Úlceras gástricas activas</h4>
                  <p>Si tienes úlceras gástricas en curso, la gastroplastia endoscópica podría no ser adecuada.</p>
                </div>
              </Col>

              <Col xl={4} data-aos="fade-up" data-aos-delay="300" className="order-3">
                <div className="icon-box d-flex flex-column justify-content-center align-items-center border-red">
                  <i className="bi bi bi-ban"></i>
                  <h4>Poliposis gástrica</h4>
                  <p>Esta condición podría afectar la seguridad del procedimiento.</p>
                </div>
              </Col>

              <Col xl={4} data-aos="fade-up" data-aos-delay="400" className="order-2">
                <div className="icon-box d-flex flex-column justify-content-center align-items-center border-red">
                  <i className="bi bi bi-ban"></i>
                  <h4>Cirrosis Hepática</h4>
                  <p>La cirrosis hepática puede aumentar los riesgos asociados con el procedimiento.</p>
                </div>
              </Col>
            </Row>
          </Col>

        </Row>
      </Container>
    </section>
  );
}

export default CandidatosSection;

