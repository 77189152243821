import React from "react";
import '../../assets/css/extras/btnWsp.css';

const FloatingButton = ({ href, ariaLabel, iconClass, extraClass }) => (
  <div className={`btn-flotante ${extraClass}`}>
    <a
      aria-label={ariaLabel}
      rel="noopener noreferrer"
      href={href}
      target={href.startsWith("http") ? "_blank" : "_self"}
    >
      <i className={`bi ${iconClass}`}></i>
    </a>
  </div>
);

const BotonWsp = ({ whatsappNumber, phoneNumber }) => (
  <>
    <FloatingButton
      href={`https://api.whatsapp.com/send?phone=${whatsappNumber}`}
      ariaLabel="Botón de Mensaje WhatsApp"
      iconClass="bi-whatsapp"
      extraClass="btn-whatsapp"
    />
    <FloatingButton
      href={`tel:${phoneNumber}`}
      ariaLabel="Botón de Llamada"
      iconClass="bi-telephone"
      extraClass="btn-call"
    />
  </>
);

export default BotonWsp;
